.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .product__item:hover .image-overlay {
    opacity: 1;
  }
  
  .image-overlay img {
    max-width: 180%;
    max-height: 180%;
    transition: transform 0.3s ease-in-out;
  }
  
  .product__item:hover .image-overlay img {
    transform: scale(1.2); /* Adjust the scale factor for zoom effect */
  }
  .product__hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1; /* Ensure the hover elements are on top */
  }
  
  .product__item:hover .product__hover {
    opacity: 1;
  }
  /* Adjusted CSS for the img element */
.product__big__img {
  /* Add any existing styles */
  /* Example styles: */
  max-width: 100%;
  height: auto;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
}

/* Adjusted CSS for the image overlay */
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.product__big__img:hover + .image-overlay {
  opacity: 1;
}

/* Adjusted CSS for the zoom effect */
.image-overlay img {
  max-width: 180%;
  max-height: 180%;
  transition: transform 0.3s ease-in-out;
}

.product__big__img:hover + .image-overlay img {
  transform: scale(1.2); /* Adjust the scale factor for zoom effect */
}

/* Adjusted CSS for the product hover */
.product__hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1; /* Ensure the hover elements are on top */
}

.product__big__img:hover + .image-overlay .product__hover {
  opacity: 1;
}
.zoom-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.zoomed-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomed-image {
  max-width: 80%;
  max-height: 80%;
}

.close-zoom {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}