.sticky-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 9999; /* Adjust the background color to your header's style */
  /* Add any other styles you want for your header */
}
.mobile-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-container {
  display: flex !important;
  align-items: center !important;
}
