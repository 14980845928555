.myform {
  
    background: #e9e9e9;
    color: #666666;
    font-family: "RobotoDraft", "Roboto", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Pen Title */
  .myform .pen-title {
    padding: 50px 0;
    text-align: center;
    letter-spacing: 2px;
  }
  
  .myform .pen-title h1 {
    margin: 0 0 20px;
    font-size: 48px;
    font-weight: 300;
  }
  
  .myform .pen-title span {
    font-size: 12px;
  }
  
  .myform .pen-title span .fa {
    color: #ed2553;
  }
  
  .myform .pen-title span a {
    color: #ed2553;
    font-weight: 600;
    text-decoration: none;
  }
  
  /* Rerun */
  .myform .rerun {
    margin: 0 0 30px;
    text-align: center;
  }
  
  .myform .rerun a {
    cursor: pointer;
    display: inline-block;
    background: #ed2553;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 10px 20px;
    color: #ffffff;
    text-decoration: none;
    transition: 0.3s ease;
  }
  
  .myform .rerun a:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  /* Scroll To Bottom */
  .myform #codepen,
  .myform #portfolio {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: #363636;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s ease;
    color: #ffffff;
    text-align: center;
  }
  
  .myform #codepen i,
  .myform #portfolio i {
    line-height: 56px;
  }
  
  .myform #codepen:hover,
  .myform #portfolio:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  
  /* CodePen */
  .myform #portfolio {
    bottom: 96px;
    right: 36px;
    background: #ed2553;
    width: 44px;
    height: 44px;
    -webkit-animation: buttonFadeInUp 1s ease;
    animation: buttonFadeInUp 1s ease;
  }
  
  .myform #portfolio i {
    line-height: 44px;
  }
  
  /* Container */
  .myform .container {
    position: relative;
    max-width: 460px;
    width: 100%;
    margin: 0 auto 100px;
  }
  
  .myform .container.active .card:first-child {
    background: #f2f2f2;
    margin: 0 15px;
  }
  
  .myform .container.active .card:nth-child(2) {
    background: #fafafa;
    margin: 0 10px;
  }
  
  .myform .container.active .card.alt {
    top: 20px;
    right: 0;
    width: 100%;
    min-width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 60px 0 40px;
    overflow: hidden;
  }
  
  .myform .container.active .card.alt .toggle {
    position: absolute;
    top: 40px;
    right: -70px;
    box-shadow: none;
    transform: scale(10);
    transition: transform 0.3s ease;
  }
  
  .myform .container.active .card.alt .toggle:before {
    content: "";
  }
  
  .myform .container.active .card.alt .title,
  .myform .container.active .card.alt .input-container,
  .myform .container.active .card.alt .button-container {
    left: 0;
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease;
  }
  
  .myform .container.active .card.alt .title {
    transition-delay: 0.3s;
  }




.myform .container.active .card.alt .input-container {
  transition-delay: 0.4s;
}



.myform .container.active .card.alt .input-container:nth-child(2) {
    transition-delay: 0.5s;
  }
  
  .myform .container.active .card.alt .input-container:nth-child(3) {
    transition-delay: 0.6s;
  }
  
  .myform .container.active .card.alt .button-container {
    transition-delay: 0.7s;
  }
  
  /* Card */
  .myform .card {
    position: relative;
    background: #ffffff;
    border-radius: 5px;
    padding: 60px 0 40px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s ease;
  }
  
  .myform .card:first-child {
    background: #fafafa;
    height: 10px;
    border-radius: 5px 5px 0 0;
    margin: 0 10px;
    padding: 0;
  }
  
  .myform .card .title {
    position: relative;
    z-index: 1;
    border-left: 5px solid #ed2553;
    margin: 0 0 35px;
    padding: 10px 0 10px 50px;
    color: #ed2553;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .myform .card .input-container {
    position: relative;
    margin: 0 60px 50px;
  }
  
  .myform .card .input-container input {
    outline: none;
    z-index: 1;
    position: relative;
    background: none;
    width: 100%;
    height: 60px;
    border: 0;
    color: #212121;
    font-size: 24px;
    font-weight: 400;
  }
  
  .myform .card .input-container input:focus ~ label {
    color: #9d9d9d;
    transform: translate(-12%, -50%) scale(0.75);
  }
  
  .myform .card .input-container input:focus ~ .bar:before,
  .myform .card .input-container input:focus ~ .bar:after {
    width: 50%;
  }
  
  .myform .card .input-container input:valid ~ label {
    color: #9d9d9d;
    transform: translate(-12%, -50%) scale(0.75);
  }
  
  .myform .card .input-container label {
    position: absolute;
    top: 0;
    left: 0;
    color: #757575;
    font-size: 24px;
    font-weight: 300;
    line-height: 60px;
    transition: 0.2s ease;
  }
  
  .myform .card .input-container .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #757575;
    width: 100%;
    height: 1px;
  }
  
  .myform .card .input-container .bar:before,
  .myform .card .input-container .bar:after {
    content: "";
    position: absolute;
    background: #ed2553;
    width: 0;
    height: 2px;
    transition: 0.2s ease;
  }
  
  .myform .card .input-container .bar:before {
    left: 50%;
  }
  
  .myform .card .input-container .bar:after {
    right: 50%;
  }
  
  .myform .card .button-container {
    margin: 0 60px;
    text-align: center;
  }
  
  .myform .card .button-container button {
    outline: 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: 0;
    width: 240px;
    border: 2px solid #e3e3e3;
    padding: 20px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.3s ease;
  }
  
  .myform .card .button-container button span {
    position: relative;
    z-index: 1;
    color: #ddd;
    transition: 0.3s ease;
  }
  
  .myform .card .button-container button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background: #ed2553;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    transition: 0.3s ease;
  }
  
  .myform .container.active .card.alt .input-container:nth-child(2) {
    transition-delay: 0.5s;
  }
  
  .myform .container.active .card.alt .input-container:nth-child(3) {
    transition-delay: 0.6s;
  }
  
  .myform .container.active .card.alt .button-container {
    transition-delay: 0.7s;
  }
  
  /* Card */
  .myform .card {
    position: relative;
    background: #ffffff;
    border-radius: 5px;
    padding: 60px 0 40px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s ease;
  }
  
  .myform .card:first-child {
    background: #fafafa;
    height: 10px;
    border-radius: 5px 5px 0 0;
    margin: 0 10px;
    padding: 0;
  }
  
  .myform .card .title {
    position: relative;
    z-index: 1;
    border-left: 5px solid #ed2553;
    margin: 0 0 35px;
    padding: 10px 0 10px 50px;
    color: #ed2553;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .myform .card .input-container {
    position: relative;
    margin: 0 60px 50px;
  }
  
  .myform .card .input-container input {
    outline: none;
    z-index: 1;
    position: relative;
    background: none;
    width: 100%;
    height: 60px;
    border: 0;
    color: #212121;
    font-size: 24px;
    font-weight: 400;
  }
  
  .myform .card .input-container input:focus ~ label {
    color: #9d9d9d;
    transform: translate(-12%, -50%) scale(0.75);
  }
  
  .myform .card .input-container input:focus ~ .bar:before,
  .myform .card .input-container input:focus ~ .bar:after {
    width: 50%;
  }
  
  .myform .card .input-container input:valid ~ label {
    color: #9d9d9d;
    transform: translate(-12%, -50%) scale(0.75);
  }
  
  .myform .card .input-container label {
    position: absolute;
    top: 0;
    left: 0;
    color: #757575;
    font-size: 24px;
    font-weight: 300;
    line-height: 60px;
    transition: 0.2s ease;
  }
  
  .myform .card .input-container .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #757575;
    width: 100%;
    height: 1px;
  }
  
  .myform .card .input-container .bar:before,
  .myform .card .input-container .bar:after {
    content: "";
    position: absolute;
    background: #ed2553;
    width: 0;
    height: 2px;
    transition: 0.2s ease;
  }
  
  .myform .card .input-container .bar:before {
    left: 50%;
  }
  
  .myform .card .input-container .bar:after {
    right: 50%;
  }
  
  .myform .card .button-container {
    margin: 0 60px;
    text-align: center;
  }
  
  .myform .card .button-container button {
    outline: 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: 0;
    width: 240px;
    border: 2px solid #e3e3e3;
    padding: 20px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.3s ease;
  }
  
  .myform .card .button-container button span {
    position: relative;
    z-index: 1;
    color: #ddd;
    transition: 0.3s ease;
  }
  
  .myform .card .button-container button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background: #ed2553;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    transition: 0.3s ease;
  }
  



.myform .card .button-container button:hover, .card .button-container button:active, .card .button-container button:focus {
  border-color: #ed2553;
}



.myform .card .button-container button:hover span,
.myform .card .button-container button:active span,
.myform .card .button-container button:focus span {
  color: #ed2553;
}

.myform .card .button-container button:active span,
.myform .card .button-container button:focus span {
  color: #ffffff;
}

.myform .card .button-container button:active:before,
.myform .card .button-container button:focus:before {
  opacity: 1;
  transform: scale(10);
}

.myform .card .footer {
  margin: 40px 0 0;
  color: #d3d3d3;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}

.myform .card .footer a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease;
}

.myform .card .footer a:hover {
  color: #bababa;
}

.myform .card.alt {
  position: absolute;
  top: 40px;
  right: -70px;
  z-index: 10;
  width: 140px;
  height: 140px;
  background: none;
  border-radius: 100%;
  box-shadow: none;
  padding: 0;
  transition: 0.3s ease;
}

.myform .card.alt .toggle {
  position: relative;
  background: #ed2553;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #ffffff;
  font-size: 58px;
  line-height: 140px;
  text-align: center;
  cursor: pointer;
}

.myform .card.alt .toggle:before {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.myform .card.alt .title,
.myform .card.alt .input-container,
.myform .card.alt .button-container {
  left: 100px;
  opacity: 0;
  visibility: hidden;
}

.myform .card.alt .title {
  position: relative;
  border-color: #ffffff;
  color: #ffffff;
}

.myform .card.alt .title .close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 60px;
  display: inline;
  color: #ffffff;
  font-size: 58px;
  font-weight: 400;
}

.myform .card.alt .title .close:before {
  content: "×";
}

.myform .card.alt .input-container input {
  color: #ffffff;
}

.myform .card.alt .input-container input:focus ~ label {
  color: #ffffff;
}

.myform .card.alt .input-container input:focus ~ .bar:before,
.myform .card.alt .input-container input:focus ~ .bar:after {
  background: #ffffff;
}

.myform .card.alt .input-container input:valid ~ label {
  color: #ffffff;
}

.myform .card.alt .input-container label {
  color: rgba(255, 255, 255, 0.8);
}

.myform .card.alt .input-container .bar {
  background: rgba(255, 255, 255, 0.8);
}

.myform .card.alt .input-container .bar:before,
.myform .card.alt .input-container .bar:after {
  content: "";
  position: absolute;
  background: #ed2553;
  width: 0;
  height: 2px;
  transition: 0.2s ease;
}

.myform .card.alt .input-container .bar:before {
  left: 50%;
}

.myform .card.alt .input-container .bar:after {
  right: 50%;
}

.myform .card.alt .button-container button {
  width: 100%;
  background: #ffffff;
  border-color: #ffffff;
}

.myform .card.alt .button-container button span {
  color: #ed2553;
}

.myform .card.alt .button-container button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.myform .card.alt .button-container button:active:before,
.myform .card.alt .button-container button:focus:before {
  display: none;
}

/* Keyframes */
@-webkit-keyframes buttonFadeInUp {
  0% {
    bottom: 30px;
    opacity: 0;
  }
}

@keyframes buttonFadeInUp {
  0% {
    bottom: 30px;
    opacity: 0;
  }
}


.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.auth-form label {
  font-weight: bold;
}

.auth-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auth-form button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-form button:hover {
  background-color: #0056b3;
}

.auth-form p {
  margin-top: 10px;
  cursor: pointer;
  color: #007bff;
}

.auth-form p:hover {
  text-decoration: underline;
}
